import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { fetchUserOrders, updateOrderStatus } from "../../hooks/api"; // Import API functions
import CustomSnackbar from "../../Snackbar/Snackbar";
import "./FetchUserDetails.css";
import { ORDER_STATUSES } from "../../constants/constants";

const FetchUserDetails = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

 
  const { data, error, isLoading } = useQuery(
    ["userOrders", localStorage.getItem("searchValue")],
    () => fetchUserOrders(localStorage.getItem("searchValue")));

 
  const filteredOrders =
    data?.data?.order_details?.filter(
      (order) =>
        order.status?.id === ORDER_STATUSES.CANCELLATION_REQUESTED ||
        order.status?.id === ORDER_STATUSES.CANCELLED
    ) || [];

 
  const mutation = useMutation({
    mutationFn: updateOrderStatus, 
    onSuccess: (result, variables) => {
  
      queryClient.invalidateQueries(["userOrders", localStorage.getItem("searchValue")]);
    },
    onError: (error) => {
  
      setSnackbar({
        open: true,
        message: error.message,
        severity: "error",
      });
    },
  });

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  
  const handleAction = (orderId, action) => {
    mutation.mutate({ orderId, action }); 
  };

 
  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  
  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className="container">
      <div className="header">
        <span className="details-title">Order Cancellations</span>
        <button className="back-button" onClick={handleBack}>
          Back
        </button>
      </div>
      <table className="data-table">
        <thead>
          <tr>
            <th>Order ID</th>
            <th>Amount Paid</th>
            <th>Order Amount</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <td colSpan="4" className="status-message">
                Loading...
              </td>
            </tr>
          ) : error ? (
            <tr>
              <td colSpan="4" className="status-message error">
                Error: {error.message}
              </td>
            </tr>
          ) : filteredOrders.length > 0 ? (
            filteredOrders.map((row, index) => {
              const statusName = row.status?.name || "N/A";

              return (
                <tr key={index}>
                  <td>{row.order_id || "N/A"}</td>
                  <td>{row.amount_paid || "N/A"}</td>
                  <td>{row.amount || "N/A"}</td>
                  <td>
                    {statusName === "CANCELLATION_REQUESTED" ? (
                      <div>
                        <span>{statusName}</span>
                        <div className="status-buttons-container">
                          <button
                            className="approve-button"
                            onClick={() => handleAction(row.order_id, "APPROVED")}
                          >
                            Approve
                          </button>
                          <button
                            className="reject-button"
                            onClick={() => handleAction(row.order_id, "REJECTED")}
                          >
                            Reject
                          </button>
                        </div>
                      </div>
                    ) : (
                      <span>{statusName}</span>
                    )}
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan="4" className="no-data">
                No matching orders found.
              </td>
            </tr>
          )}
        </tbody>
      </table>

     
      <CustomSnackbar
        open={snackbar.open}
        onClose={handleCloseSnackbar}
        message={snackbar.message}
        severity={snackbar.severity}
      />
    </div>
  );
};

export default FetchUserDetails;
