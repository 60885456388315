import React from 'react';
import { Link } from 'react-router-dom';

import { Home, Upload,History } from '@mui/icons-material'; 

import './Sidebar.css';

const Sidebar = () => {
    return (
        <div className="sidebar">
            <Link to="/Searchuser" className="icon-link">
                <Home className="icon" sx={{ fontSize: 42 }} />
            </Link>
            <Link to="/upload" className="icon-link">
                <Upload className="icon" sx={{ fontSize: 42 }} /> 
            </Link>
            <Link to="/history" className="icon-link">
                <History className="icon" sx={{ fontSize: 42 }} /> 
            </Link>
        </div>
    );
};

export default Sidebar;
