import React, { useState } from "react";

import { useFetchHistory, useFetchProcessedFile } from "../../hooks/api";
import CustomTable from "../../widgets/Table";
import { useSnackbarContext } from "../../widgets/CustomSnackBar/CustomSnackBar";
import PrimaryButton from "../../widgets/Button/PrimaryButton/PrimaryButton";
import { FILE_UPLOAD_STATUS } from "../../constants/constants";
import Loader from "../../components/Loader/Loader";

import "./History.css";

const historyTableColumns = [
  { label: "Name", field: "name" },
  { label: "Script", field: "script" },
  { label: "Created At", field: "createdAt" },
  { label: "Total Rows", field: "totalRows" },
  { label: "Error Rows", field: "errorRows" },
  { label: "Total Rows", field: "totalRows" },
  { label: "Status", field: "status" },
  { label: "Download Processed File ", field: "downloadProcessedFile" },
];

const handleOnClickDownloadProcessedFile = (
  fileId,
  fetchProcessedFile,
  openSnackbar
) => {
  if (fileId) {
    fetchProcessedFile(fileId)
      .then((response) => {
        const link = document.createElement("a");
        link.href = response.signedUrl;
        link.click();
        openSnackbar("Processed File downloaded successfully!", "success");
      })
      .catch((error) => {
        console.error("Error fetching Processed File", error);
        openSnackbar(error.message || "Error fetching Processed File", "error");
      });
  } else {
    console.error("No File Id provided");
  }
};
const getHistoryTableRows = (
  history,
  fetchProcessedFile,
  openSnackbar,
) => {
  if (!Array.isArray(history)) {
    return [];
  }
  return history.map((historyRowDetail) => ({
    name: historyRowDetail.name,
    script: historyRowDetail.script.name,
    createdAt:  new Date(historyRowDetail.created_at).toLocaleString(),
    totalRows: historyRowDetail.result?.total_rows,
    errorRows: historyRowDetail.result?.error_rows,
    status: historyRowDetail.status.name,
    downloadProcessedFile:
      historyRowDetail.status.id === FILE_UPLOAD_STATUS.PROCESSED ? (
        <PrimaryButton
          onClick={() =>
            handleOnClickDownloadProcessedFile(
              historyRowDetail.file_id,
              fetchProcessedFile,
              openSnackbar
            )
          }
        >
          Download
        </PrimaryButton>
      ) : (
        <PrimaryButton disabled>Not Available Yet</PrimaryButton>
      ),
  }));
};

const History = () => {
  const { openSnackbar } = useSnackbarContext();
  const [user_id] = useState(localStorage.getItem("user_id"));

  const {
    isLoading: isHistoryLoading,
    data: history,
  } = useFetchHistory(user_id);

  const { fetchProcessedFile,  isInitialLoading: isFetching} =
    useFetchProcessedFile();

  const rows = getHistoryTableRows(
    history,
    fetchProcessedFile,
    openSnackbar,
    isFetching
  );

  return (
    <div className="rootContainer">
      <div className="historyContainer">
        <h1>Upload History</h1>
        <div className="text">Displaying the Latest 20 Uploads (Maximum)</div>
        <div className="tableContainer">
        {isHistoryLoading ? <Loader/> : <CustomTable columns={historyTableColumns} rows={rows} />}
        
        </div>
      </div>
    </div>
  );
};

export default History;
