import React from "react";
import { styled } from "@mui/system";

const StyledHeading = styled("div")(({ level }) => {
  switch (level) {
    case 1:
      return {
        fontSize: "22px",
        fontWeight: "500",
        lineHeight: "32px",
        letterSpacing: "0em",
        textAlign: "center",
        color: "#172B4D",
        whiteSpace: "nowrap"
      };
    case 2:
      return {
        fontSize: "20px",
        fontWeight: "500",
        lineHeight: "28px",
        textAlign: "center",
        color: "#172B4D",
      };
    case 3:
      return {
        fontSize: "16px",
        fontWeight: "500",
        lineHeight: "24px",
        textAlign: "center",
        color: "#344563",
      };
    case 4:
      return {
        fontSize: "14px",
        fontWeight: "400",
        lineHeight: "20px",
        textAlign: "center",
        color: "#42526E",
      };
    default:
      return {
        fontSize: "12px",
        fontWeight: "400",
        lineHeight: "16px",
        textAlign: "center",
        color: "#6B778C",
      };
  }
});

const Heading1 = ({ heading, level = 1 }) => (
  <StyledHeading level={level}>
    {heading}
  </StyledHeading>
);

export default Heading1;
