import React from "react";

import Select from "../Select/Select";
import MenuItem from "../MenuItem/CustomMenuItem";

import { styled } from "@mui/system";

const StyledContainer = styled("div")(() => ({
  margin: "6px 0px",
  width: "100%",
}));



const SingleSelect = (props) => {
  const {
    id,
    name,
    value,
    label,
    placeholder,
    handleChange,
    menuItems,
    defaultSelected,
    onBlur,
  } = props;
  const selectProps = {
    id,
    name,
    value,
    label,
  };

  return (
    <StyledContainer>
      <Select
        {...selectProps}
        multiline
        defaultValue={defaultSelected}
        displayEmpty
        renderValue={(selected) => {
          return selected || placeholder;
        }}
        onChange={handleChange}
        onBlur={onBlur}
        sx={{ textAlign: "left" }}
      >
        {menuItems.map((menuItem) => (
          <MenuItem key={menuItem.key} value={menuItem.value}>
            {menuItem.label}
          </MenuItem>
        ))}
      </Select>
    </StyledContainer>
  );
};

export default SingleSelect;
