import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { useLogin } from '../../store/backend/uselogin';
import useAuth from '../../hooks/useAuth';
import PrimaryButton from '../../widgets/Button/PrimaryButton/PrimaryButton';
import routes from '../../constants/routes';

import './LoginContainer.css';

const handleOnSuccessLogin = (data, authLogin, navigate, setPhoneNumber, setAdmissionNumber) => {
    authLogin(data.accessToken);
    setPhoneNumber(data.phoneNumber);
    setAdmissionNumber(data.admission_number);
    localStorage.setItem('phoneNumber', data.phoneNumber);
    localStorage.setItem('admissionNumber', data.admission_number);
    localStorage.setItem("user_id", data.crnId);
    navigate(routes.SearchUser);
};

const LoginContainer = ({ setPhoneNumber, setAdmissionNumber }) => {
    const navigate = useNavigate();
    const { login: authLogin } = useAuth();

    const { mutate: login, isLoading, isError, error } = useLogin(
        (data) => handleOnSuccessLogin(data, authLogin, navigate, setPhoneNumber, setAdmissionNumber),
    );

    const formik = useFormik({
        initialValues: {
            admissionNumber: '',
            password: '',
        },
        validationSchema: Yup.object({
            admissionNumber: Yup.string().required('User ID is required'),
            password: Yup.string().required('Password is required'),
        }),
        onSubmit: (values) => {
            login(values);
        },
    });

    return (
        <div className="login-container">
            <div className="login-box">
                <form onSubmit={formik.handleSubmit}>
                    <span>Login</span>

                    <input
                        id="admissionNumber"
                        type="text"
                        name="admissionNumber"
                        value={formik.values.admissionNumber}
                        placeholder="Enter User ID"
                        className="form-control inp_text"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                    {formik.touched.admissionNumber && formik.errors.admissionNumber && (
                        <p className="error">{formik.errors.admissionNumber}</p>
                    )}

                    <input
                        className="form-control"
                        type="password"
                        placeholder="Enter password"
                        name="password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                    {formik.touched.password && formik.errors.password && (
                        <p className="error">{formik.errors.password}</p>
                    )}

                    {isError && (
                        <p className="login-error-message">
                            {error.message || "Something went wrong"}
                        </p>
                    )}

                    <div className="login-button-container">
                        <PrimaryButton
                            type="submit"
                            className="login-button"
                            disabled={isLoading}
                        >
                            {isLoading ? <div className="loader"></div> : 'Login'}
                        </PrimaryButton>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default LoginContainer;
