const routes = {
    Login: '/Login',
    SearchUser: '/Searchuser',
    UserDetails: '/userdetails/:type/:id',
    CallLogs: '/call-logs/:id',
    CancelledOrders: '/CancelledOrders',
    upload: '/upload',
    history: '/history'

};
export default routes;