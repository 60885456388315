const idtype = {
  CRN: "CRN",
  UAM: "UAM",
};

export default idtype;

export const VISIBLE_PHONE_DIGITS = 2;
export const EDITABLE_TIME_LIMIT_MS = 3 * 60 * 60 * 1000;

export const ORDER_STATUSES = {
  CANCELLATION_REQUESTED: 4,
  CANCELLED: 5,
};

export const SELECTED_SCRIPT_IDS = {
  UPDATE_ORDER_MODIFICATION_REQUEST: 3,
};

export const UPLOAD_SCRIPT_TYPE = [
  {
    key: 3,
    label: "Update Order Modification Request",
    value: "UPDATE_ORDER_MODIFICATION_REQUEST",
  },
];

export const ADMIN_SUPPORT_MODULE_ID = 2

export const FILE_UPLOAD_STATUS = {
  CREATED: 1,
  UPLOADED: 2,
  PROCESSED: 3,
};
