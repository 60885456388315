export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export const API_SECRET_KEY = process.env.REACT_APP_API_SECRET_KEY;
export const LOGIN_ENDPOINT = process.env.REACT_APP_LOGIN_ENDPOINT;
export const LOGIN_PRODUCT_ID = process.env.REACT_APP_LOGIN_PRODUCT_ID;
export const LOGIN_TENANT_ID = process.env.REACT_APP_LOGIN_TENANT_ID;
export const CALL_API_ENDPOINT = process.env.REACT_APP_CALL_API_ENDPOINT;
export const CALL_API_SECRET_TOKEN = process.env.REACT_APP_CALL_API_SECRET_TOKEN;
export const PLATFORM = process.env.REACT_APP_PLATFORM;
export const ISD_CODE = process.env.REACT_APP_ISD_CODE;
export const REQUEST_PRODUCT_ID = process.env.REACT_APP_REQUEST_PRODUCT_ID;
export const PRODUCT_NAME = process.env.REACT_APP_PRODUCT_NAME;
export const FETCH_RECENT_CALL_LOGS_API_ENDPOINT = process.env.REACT_APP_FETCH_RECENT_CALL_LOGS_API_ENDPOINT;
export const UPDATENOTE_API_ENDPOINT = process.env.REACT_APP_UPDATENOTE
export const VALID_ROLE_IDS = [
    Number(process.env.REACT_APP_VALID_CS_ADMIN_ROLE_ID),
    Number(process.env.REACT_APP_VALID_CS_SUPER_ADMIN_ROLE_ID),
  ];
  