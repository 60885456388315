import { useMutation } from '@tanstack/react-query';
import useAuth from '../../hooks/useAuth';
import { LOGIN_ENDPOINT, LOGIN_PRODUCT_ID, LOGIN_TENANT_ID, ISD_CODE, REQUEST_PRODUCT_ID, PLATFORM, VALID_ROLE_IDS } from '../../constants/config';

const login = async ({ admissionNumber, password }) => {
    const response = await fetch(LOGIN_ENDPOINT, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'platform': PLATFORM,
            'product-id': LOGIN_PRODUCT_ID,
            'tenant-id': LOGIN_TENANT_ID,
        },
        body: JSON.stringify({
            admission_number: admissionNumber,
            isd_code: ISD_CODE,
            phone: admissionNumber,
            password: password,
            product_id: REQUEST_PRODUCT_ID,
            tenant_id: LOGIN_TENANT_ID,
        })
    });

    if (!response.ok) {
        const errorData = await response.json();
        const errorMessage = errorData.detail || 'Unable to log in';
        throw new Error(errorMessage);
    }

    return response.json();
};

export const useLogin = (onSuccess) => {
  const { login: authLogin } = useAuth();
  const mutation = useMutation(login, {
    onSuccess: (data) => {
      if (data.accessToken) {
        if (
          data.roles &&
          data.roles.some((roleId) =>
            VALID_ROLE_IDS.map(Number).includes(roleId)
          )
        ) {
          authLogin(data.accessToken);
          onSuccess(data);
        } else {
          throw new Error(
            "User does not have the required permissions to log in."
          );
        }
      } else {
        throw new Error("No access token in response");
      }
    },
    onError: (error) => {
      throw new Error("Login error: ", error.message);
    },
  });

  return { ...mutation };
};