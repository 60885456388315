import React, { useState } from "react";

import PrimaryButton from "../../widgets/Button/PrimaryButton/PrimaryButton";
import SingleSelect from "../../widgets/Dropdown/SingleSelectDropdown";
import ErrorMessage from "../../widgets/ErrorMessage/ErrorMessage";
import {
  SELECTED_SCRIPT_IDS,
  UPLOAD_SCRIPT_TYPE,
} from "../../constants/constants";
import Heading1 from "../../widgets/Typography/Heading1";
import { Input } from "@mui/material";
import { useUploadCsv } from "../../hooks/api";
import { useSnackbarContext } from "../../widgets/CustomSnackBar/CustomSnackBar";

import "./Upload.css";

const is_valid_file = (file) => {
  if (!file) {
    return false;
  }
  const fileExtension = file.name.split(".").pop().toLowerCase();
  return fileExtension === "csv";
};

const handleFileChange = (event, setFile, setFileError) => {
  const selectedFile = event.target.files[0];
  if (!is_valid_file(selectedFile)) {
    setFileError("Invalid file format. Please upload a CSV file.");
  } else {
    setFileError("");
    setFile(selectedFile);
  }
};

const handleSubmit = (
  file,
  selectedScriptId,
  openSnackbar,
  processUploadCsv,
  uploaderUserId
) => {
  processUploadCsv(
    { file, selectedScriptId, uploaderUserId },
    {
      onSuccess: (data) => {
        if (!data) {
          throw new Error("Received data is undefined");
        }
        if (data.uploaded_file_id) {
          openSnackbar(`File Uploaded Successfully!`, "success");
        }
      },
      onError: (err) => {
        openSnackbar(err.message || "Download failed", "error");
      },
    }
  );
};
const Upload = () => {
  const { openSnackbar } = useSnackbarContext();

  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedScriptId, setSelectedScriptId] = useState("");
  const [fileError, setFileError] = useState("");
  const [uploaderUserId] = useState(localStorage.getItem("user_id"));

  const { mutate: processUploadCsv, isPending: isUploadPending } =
    useUploadCsv();

  return (
    <div className="mainContainer">
      <div className="dashedBox">
      <h1>Upload File</h1>
        <div className="flexContainer">
          <Heading1 heading="Choose an action" />
          <SingleSelect
            menuItems={UPLOAD_SCRIPT_TYPE}
            placeholder="Select an action"
            handleChange={(event) =>
              setSelectedScriptId(SELECTED_SCRIPT_IDS[event.target.value])
            }
            selectedValue={selectedScriptId}
          />
        </div>
        {selectedScriptId && (
          <>
            <div className="flexContainer">
              <Heading1 heading="Upload File" />
              <Input
                id="file-upload"
                type="file"
                display="none"
                className="inputField"
                onChange={(event) =>
                  handleFileChange(event, setSelectedFile, setFileError)
                }
              />
            </div>
            {selectedFile && (
              <div>
                <span>Selected File : </span>
                <span className="selectedFile">{selectedFile.name}</span>
              </div>
            )}
          </>
        )}
      </div>
      <div className="button">
        <PrimaryButton
          onClick={() =>
            handleSubmit(
              selectedFile,
              selectedScriptId,
              openSnackbar,
              processUploadCsv,
              uploaderUserId
            )
          }
          disabled={
            !selectedFile || fileError || !selectedScriptId || isUploadPending
          }
        >
          {isUploadPending ? "Uploading..." : "Upload"}
        </PrimaryButton>
      </div>
      {fileError && <ErrorMessage>{fileError}</ErrorMessage>}
    </div>
  );
};

export default Upload;
