import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import CustomDialog from '../../widgets/Dialog/Dialog';
import useFetchUserDetails from '../../store/backend/useFetchUserDetails';
import SecondaryButton from '../../widgets/Button/SecondaryButton/SecondaryButton';

import './SearchUserContainer.css';

const handleSearchResponse = (response, navigate, searchValue, setShowErrorPopup, setLoading) => {
    setLoading(false); 
    if (response.isError || !response.data) {
        setShowErrorPopup(true);
    } else {
        localStorage.setItem("searchValue", searchValue);
        navigate(`/userdetails/UAM/${searchValue}`);
    }
};

const handleSearch = async (searchValue, refetchUserDetails, navigate, setShowErrorPopup, setLoading) => {
    if (searchValue.trim()) {
        setLoading(true);
        const response = await refetchUserDetails();
        handleSearchResponse(response, navigate, searchValue, setShowErrorPopup, setLoading);
    }
};

const SearchUserContainer = () => {
    const navigate = useNavigate();
    const [searchValue, setSearchValue] = useState('');
    const [showErrorPopup, setShowErrorPopup] = useState(false);
    const [loading, setLoading] = useState(false);
    const { refetch: refetchUserDetails } = useFetchUserDetails(searchValue, 'UAM');

    const handleSearchValueChange = (e) => setSearchValue(e.target.value);

    const handleCloseErrorPopup = () => setShowErrorPopup(false);

    return (
        <div className="search-user-container">
            <h1 className="search-user-title">Search User</h1>
            <div className="search-inputs">
                <input
                    className="search-input"
                    type="text"
                    value={searchValue}
                    onChange={handleSearchValueChange}
                    placeholder="Enter UAM ID"
                    disabled={loading}
                />
                <SecondaryButton
                    className="search-button"
                    onClick={() => handleSearch(searchValue, refetchUserDetails, navigate, setShowErrorPopup, setLoading)}
                    disabled={loading}
                >
                    {loading ? 'Searching...' : 'Search'}
                </SecondaryButton>
            </div>
            <CustomDialog isOpen={showErrorPopup} handleOnClose={handleCloseErrorPopup}>
                <div className="popup-content">
                    <p>User not found. Please try again.</p>
                    <div className="button-container">
                        <SecondaryButton onClick={handleCloseErrorPopup}>
                            Close
                        </SecondaryButton>
                    </div>
                </div>
            </CustomDialog>
        </div>
    );
};

export default SearchUserContainer;