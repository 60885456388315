import React from "react";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { BrowserRouter as Router } from "react-router-dom";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ErrorBoundary } from "react-error-boundary";

import { logger } from "./utils/logger";
import ErrorFallback from "./components/ErrorFallback/ErrorFallback";
import { queryClient, persister } from "./store/serverStore";
import GlobalStyles from "@mui/material/GlobalStyles";
import HeaderNavbar from "./components/HeaderNavbar/HeaderNavbar";

import Routes from "./Routes";
import getGlobalStyles from "./globalStyles";
import SnackbarProvider from "./widgets/CustomSnackBar/CustomSnackBar";

function App() {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onError={logger}>
      <GlobalStyles styles={getGlobalStyles()} />
      <SnackbarProvider>
        <PersistQueryClientProvider
          client={queryClient}
          persistOptions={{
            persister,
          }}
        >
          <ReactQueryDevtools initialIsOpen={false} />
          <Router>
            <HeaderNavbar />
            <Routes />
          </Router>
        </PersistQueryClientProvider>
      </SnackbarProvider>
    </ErrorBoundary>
  );
}

export default App;
